<script>
import Appbar from './components/app_bar.vue'
  export default {
    name: 'App',
    components: {
      Appbar
    }
  }
</script>
<template>
  <Appbar/>
</template>
